<template>
  <div class="row">
    <div class="col-1"></div>
    <div class="col-10">
      <h1 class="align-content-center bold title mb-0" style="font-family: SBSansDisplaySemiBold">
        AI Eye-tracker
      </h1>
      <h2 class="align-content-center bold" style="font-family: SBSansDisplaySemiBold">
        Оценка заметности различных зон изображения
      </h2>
      <br />
      <div class="left">
        <h4 align="center" class="bold" style="font-family: SBSansDisplaySemiBold;">Изображение</h4>
        <div style="
              background-color: white;
              border-radius: 15px;
              min-height: 550px;
            ">
          <div class="p-3" style="overflow-y:auto">
            <div align="center" class="text-aling-center">
              <span class="main-text text-aling-center">Пожалуйста, загружайте картинки высокого качества.</span>
              <span><br /></span>
              <span class="main-text text-aling-center">Обработка займет до 3-х минут.</span>
            </div>

            <div class="">
              <vs-upload automatic show-upload-button="false" class="main-text pl-0 bold" style="font-size: larger;"
                :action="this.$hostnamejava + '/eyetracking/upload-sequence'"
                         fileName="file" :data="avatarForm"
                text="Загрузить файл" multiple="false" />
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        <h4 align="center" class="bold" style="font-family: SBSansDisplaySemiBold;">Тепловая карта</h4>
        <div style="
              background-color: white;
              border-radius: 15px;
              min-height: 550px;
            ">
          <div class="p-3">
            <img v-if="output_image" :src="this.output_image"
              style="max-width: 468px; max-height: 450px; object-fit: contain;" />
            <div class="text-center">
              <a v-if="output_image" style="max-width: 300px" @click="download()" class="btn btn-outline-primary mt-4"
                target="_blank">Скачать <download-icon class="fea icon-sm"></download-icon></a>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <h4 align="center" class="bold" style="font-family: SBSansDisplaySemiBold;">Легенда</h4>
        <div style="
              background-color: white;
              border-radius: 15px;
              height: 550px;
            ">
          <div class="p-3">
            <span class="main-text">
              <square-icon class="fea icon" style="color: rgb(78, 173, 91)" fill="green"></square-icon>
              <span style="font-family: SBSansTextSemibold;"> &nbsp;Самые заметные зоны макета:</span>
              на них пользователь обращает внимание в первую очередь. Это
              ключевая информация, которую человек считает при контакте с
              макетом.
              <br /><br />
              <square-icon class="fea icon" style="color: rgb(245, 195, 66)" fill="yellow" s></square-icon>
              <span style="font-family: SBSansTextSemibold;">&nbsp;Достаточно заметные зоны:</span>
              на них пользователь обращает внимание, но в меньшей степени.
              <br /><br />
              <span style="font-family: SBSansTextSemibold;">Зоны макета, не размеченные цветом, – наименее
                заметные.</span>
              За время контакта пользователя с макетом, он может не успеть их
              заметить и воспринять.
              <br /><br />
              <span style="font-family: SBSansTextSemibold;">Цифры</span> показывают,
              <span style="font-family: SBSansTextSemibold;">в каком порядке пользователи замечают зоны</span>
              макета. Однако порядок не равен заметности. Такой
              анализ бывает полезен лишь в некоторых случаях.
            </span>
          </div>
        </div>
      </div>
      <br /><br />
      <div style="width: 1110px">
        <h4 align="center" class="bold" style="font-family: SBSansDisplaySemiBold;">Как интерпретировать результаты</h4>
        <div style="
              background-color: white;
              border-radius: 15px;
              margin-bottom: 20px;
            ">
          <div class="p-3">
            <span class="main-text" style="font-size: 18px;">
              <span class="bold" style="color: rgb(88, 183, 119)"> Хороший результат</span>
              – это когда основная информация вашего макета (например, бренд и ключевое сообщение) попадает в «зеленую»
              или «желтую» зону.
              <br /><br />
              <span class="bold" style="color: rgb(175, 35, 24);"> Макет стоит доработать,</span>
              если ключевая информация вашего макета НЕ попадает ни в «зеленую», ни в «желтую» зону.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-1"></div>
  </div>
</template>

<script>
import store from "@/store/store";
import {
  SmileIcon,
  SquareIcon,
  MehIcon,
  DownloadIcon,
} from "vue-feather-icons";

let sseClient;

export default {
  name: "eyetracking.vue",
  components: {
    SmileIcon,
    MehIcon,
    SquareIcon,
    DownloadIcon,
  },
  data() {
    return {
      th: 200,
      alfa: 50,
      w_r: 0.8,
      w_y: 0.6,
      w_g: 0.2,
      th_r: 240,
      th_y: 170,
      th_g: 100,
      output_image: "",
    };
  },

  mounted() {
    if (this.$cookies.isKey("th")) {
      this.th = this.$cookies.get("th");
    }
    if (this.$cookies.isKey("alfa")) {
      this.alfa = this.$cookies.get("alfa");
    }

    if (this.$cookies.isKey("w_r")) {
      this.w_r = this.$cookies.get("w_r");
    } else {
      this.$cookies.set("w_r", this.w_r);
    }
    if (this.$cookies.isKey("w_y")) {
      this.w_y = this.$cookies.get("w_y");
    } else {
      this.$cookies.set("w_y", this.w_y);
    }
    if (this.$cookies.isKey("w_g")) {
      this.w_g = this.$cookies.get("w_g");
    } else {
      this.$cookies.set("w_g", this.w_g);
    }

    if (this.$cookies.isKey("th_r")) {
      this.th_r = this.$cookies.get("th_r");
    } else {
      this.$cookies.set("th_r", this.th_r);
    }
    if (this.$cookies.isKey("th_y")) {
      this.th_y = this.$cookies.get("th_y");
    } else {
      this.$cookies.set("th_y", this.th_y);
    }
    if (this.$cookies.isKey("th_g")) {
      this.th_g = this.$cookies.get("th_g");
    } else {
      this.$cookies.set("th_g", this.th_g);
    }

    sseClient = this.$sse.create({
      url: "" + this.$hostnamejava + '/eyetracking/sse-subscribe',
      format: "json",
      withCredentials: false,
      polyfill: true,
    });

    sseClient.on("message", this.handleMessage);

    sseClient
      .connect()
      .then((sse) => {
        console.log("We're connected!");
      })
      .catch((err) => {
        console.error("Failed to connect to server", err);
      });
  },
  async created() {
    this.session_hash = this.$CryptoJS.MD5("Hi There!" + new Date()).toString();
    console.log("session_hash " + this.session_hash);
  },
  watch: {
    th: function (val) {
      this.$cookies.set("th", val);
    },
    alfa: function (val) {
      this.$cookies.set("alfa", val);
    },
    w_r: function (val) {
      this.$cookies.set("w_r", val);
    },
    w_y: function (val) {
      this.$cookies.set("w_y", val);
    },
    w_g: function (val) {
      this.$cookies.set("w_g", val);
    },
    th_r: function (val) {
      this.$cookies.set("th_r", val);
    },
    th_y: function (val) {
      this.$cookies.set("th_y", val);
    },
    th_g: function (val) {
      this.$cookies.set("th_g", val);
    },
  },
  computed: {
    avatarForm: function () {
      console.log("inside avatarForm");
      return {
        user_id: store.state.user_id,
        th: this.th,
        alfa: this.alfa,
        session_hash: this.session_hash,
      };
    },
  },
  methods: {
    getImage() {
      return  "" + this.$hostnamejava + "/eyetracking/get-image/" + this.$cookies.get("eyetracking_file_hash");
    },
    handleMessage(message, lastEventId) {
      console.log(message);
      if (message.session_hash === this.session_hash) {
        if (message.status === "running") {
          this.$vs.notify({
            time: 4000,
            text: "Файл загружен и обрабатывается",
            color: "success",
          });
        }
        if (message.status === "done") {
          this.$cookies.set("eyetracking_file_hash", "" + message.hash);
          this.$vs.notify({
            time: 4000,
            text: "Файл обработан",
            color: "success",
          });
          this.output_image = this.getImage();
          const el = document.querySelector("#app > div > div.col-10 > div.left > div > div > div:nth-child(2) > div > div > div.img-upload")
          el.remove();
        }
      }
    },
    async download() {
      const blob = await (await fetch(this.output_image)).blob();
      const url = URL.createObjectURL(blob);
      Object.assign(document.createElement("a"), {
        href: url,
        download: "image.jpg",
      }).click();
      URL.revokeObjectURL(url);
    },
  },
  beforeDestroy() {
    sseClient.disconnect();
  },
};
</script>

<style scoped>

.main-text {
  color: black;
  font-size: 10pt;
  line-height: 60%;
  font-family: SBSansTextRegular;
}

.text-aling-center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.title {
  color: rgb(69, 181, 168);
}

.left,
.middle,
.right {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
}

.left {
  width: 270px;
}

.middle {
  width: 500px;
  margin-left: 20px;
  margin-right: 20px;
}

.right {
  width: 300px;
}
</style>
